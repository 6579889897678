 img {
width:100%;
}
html{
    scroll-behavior: smooth;
}

.ArrowUP{
    position: fixed;
    width: 50px;
    height: 50px;
    background: #a8247a;
    bottom: 40px;
    right: 50px;
    text-decoration: none;
    text-align: center;
    line-height: 50px;
    color: white;
    font-size: 22px;
    border-radius: 30px;
}

