ul img {
width:40px;
margin-right:25px;
}

#logo{
  width:200px;
  margin-left:23px;
}
ul{
  margin-left:"auto";
  margin-right:"auto";
}
.collapse ul li strong:hover{
  color: #ffb300;
}
.collapse ul li:hover{
  color: #ffb300;
}
#head-text{
  color:#cb3398 ;
   font-size: 26px;
   font-family:'avengeance_mightiest_avenger';
   font-weight:bold;
}
@media screen and (max-width:480px){
  #logo{
    width: 150px;
  }

  
}