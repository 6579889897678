/* Start main section */

.section{
height: 760px;   
width:100%;
  background-image: url('./1440x944.jpg');
  background-position: center;
  /* background-attachment: fixed; */
  background-size: 100%;
  
  /* height: 80vh; */
  background-repeat: no-repeat;
  color: #fff;
}


.timer{
  /* background-color: rgba(0, 0, 0, .5); */
  display: flex;
  flex: column;
  align-items: center;
  justify-content: center; 
  text-align: center;
  padding-top: 5em;
  /* padding-bottom: 6em; */
  height: 100%;
  width: 100%;
}

.leftd{
  margin-left:3vw;
}
.leftdn{
  margin-left:3vw;
}
.leftm{
  margin-left:-1vw;
}
.lefth{
  margin-left:0vw;
  
}
.lefthn{
  margin-left: 1vw;
}
.lefts{
  margin-left:-1vw;

}

 



.timer div p{
  width: 4vw; 
  
  color: #f8f8f8; 
  font-size: 2vw;
  font-family: 'avengeance_mightiest_avenger'; 
  font-weight: 400;
}



.timer div:last-child{
  margin-top: 20%;
  /* font-size: 2.5vw; */
  
  
  
  display: grid; 
  grid-template-columns: repeat(7,1fr);  
  
  text-align: center; 
}
span{

  margin-left:1vw;
}
.timer p:first-child, .timer div:last-child span{
  font-size: 5vw;
  font-weight: 700;
  margin-bottom: -13%;
}

@media screen and (max-width:500px){
  .section{
    height: 850px;   
    background-image: url('./425x850.jpg');
    background-position: 0 center;
    background-size: 100%;
    margin-top: -10vh;
    
    
  }
  .timer div p{
    width: 10vw; 
    
    color: #f8f8f8; 
    font-size: 3vw;
  }
  
  span{
    margin-right: -1vw;
  }
  .timer div:last-child{
    margin-top: -40%;    }
    
    .leftd{
      margin-left: 3vw;
    }
    .leftdn{
      margin-left: 3vw;
    }
    .lefth{
      margin-left: 2vw;
    }
    .lefthn{
      margin-right: 2vw;
    }
 
  
    .leftmn{
      margin-right: -1vw;
    }
    .timer p:first-child, .timer div:last-child span{
      font-size: 11vw;
      font-weight: 700;
    }
  }

 @media screen and (min-width:500px) and (max-width:1080px){
  .section{
    background-size: 100% 100%;
    
    
  }
  .timer div p{
    width: 4vw; 
    
    color: #f8f8f8; 
    font-size: 2vw;
  } 
  
     .timer p:first-child, .timer div:last-child span{
      font-size: 5vw;
      font-weight: 700;
    }
  } 