/* font quicksand */
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;700&display=swap");
body {
  margin: 0;
  font-family: 'avengeance_mightiest_avenger';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html{
  scroll-behavior: smooth;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/*start fonts */
@font-face{
  font-family: 'avengeance_mightiest_avenger';
  src:url(fonts/AVENGEANCE\ MIGHTIEST\ AVENGER.ttf);
  font-style: normal;
  font-weight: 100;
}
