@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;700&display=swap);
/* font quicksand */
body {
  margin: 0;
  font-family: 'avengeance_mightiest_avenger';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html{
  scroll-behavior: smooth;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/*start fonts */
@font-face{
  font-family: 'avengeance_mightiest_avenger';
  src:url("/static/media/AVENGEANCE MIGHTIEST AVENGER.fe023350.ttf");
  font-style: normal;
  font-weight: 100;
}

ul img {
width:40px;
margin-right:25px;
}

#logo{
  width:200px;
  margin-left:23px;
}
ul{
  margin-left:"auto";
  margin-right:"auto";
}
.collapse ul li strong:hover{
  color: #ffb300;
}
.collapse ul li:hover{
  color: #ffb300;
}
#head-text{
  color:#cb3398 ;
   font-size: 26px;
   font-family:'avengeance_mightiest_avenger';
   font-weight:bold;
}
@media screen and (max-width:480px){
  #logo{
    width: 150px;
  }

  
}
/* Start main section */

.section{
height: 760px;   
width:100%;
  background-image: url(/static/media/1440x944.107e931e.jpg);
  background-position: center;
  /* background-attachment: fixed; */
  background-size: 100%;
  
  /* height: 80vh; */
  background-repeat: no-repeat;
  color: #fff;
}


.timer{
  /* background-color: rgba(0, 0, 0, .5); */
  display: flex;
  flex: column 1;
  align-items: center;
  justify-content: center; 
  text-align: center;
  padding-top: 5em;
  /* padding-bottom: 6em; */
  height: 100%;
  width: 100%;
}

.leftd{
  margin-left:3vw;
}
.leftdn{
  margin-left:3vw;
}
.leftm{
  margin-left:-1vw;
}
.lefth{
  margin-left:0vw;
  
}
.lefthn{
  margin-left: 1vw;
}
.lefts{
  margin-left:-1vw;

}

 



.timer div p{
  width: 4vw; 
  
  color: #f8f8f8; 
  font-size: 2vw;
  font-family: 'avengeance_mightiest_avenger'; 
  font-weight: 400;
}



.timer div:last-child{
  margin-top: 20%;
  /* font-size: 2.5vw; */
  
  
  
  display: grid; 
  grid-template-columns: repeat(7,1fr);  
  
  text-align: center; 
}
span{

  margin-left:1vw;
}
.timer p:first-child, .timer div:last-child span{
  font-size: 5vw;
  font-weight: 700;
  margin-bottom: -13%;
}

@media screen and (max-width:500px){
  .section{
    height: 850px;   
    background-image: url(/static/media/425x850.d6f98501.jpg);
    background-position: 0 center;
    background-size: 100%;
    margin-top: -10vh;
    
    
  }
  .timer div p{
    width: 10vw; 
    
    color: #f8f8f8; 
    font-size: 3vw;
  }
  
  span{
    margin-right: -1vw;
  }
  .timer div:last-child{
    margin-top: -40%;    }
    
    .leftd{
      margin-left: 3vw;
    }
    .leftdn{
      margin-left: 3vw;
    }
    .lefth{
      margin-left: 2vw;
    }
    .lefthn{
      margin-right: 2vw;
    }
 
  
    .leftmn{
      margin-right: -1vw;
    }
    .timer p:first-child, .timer div:last-child span{
      font-size: 11vw;
      font-weight: 700;
    }
  }

 @media screen and (min-width:500px) and (max-width:1080px){
  .section{
    background-size: 100% 100%;
    
    
  }
  .timer div p{
    width: 4vw; 
    
    color: #f8f8f8; 
    font-size: 2vw;
  } 
  
     .timer p:first-child, .timer div:last-child span{
      font-size: 5vw;
      font-weight: 700;
    }
  } 
 img {
width:100%;
}
html{
    scroll-behavior: smooth;
}

.ArrowUP{
    position: fixed;
    width: 50px;
    height: 50px;
    background: #a8247a;
    bottom: 40px;
    right: 50px;
    text-decoration: none;
    text-align: center;
    line-height: 50px;
    color: white;
    font-size: 22px;
    border-radius: 30px;
}


